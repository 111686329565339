import React, { useRef } from 'react'
import { launchProject } from '../Body/BodyApi'

import { 
    Wrapper,
    ModalBg,
} from '../Modal/EditModalElements.js'

import {
    LaunchModal,
    LaunchText,
    ButtonWrapper,
    ContinueButton,
    CancelButton
} from '../Body/BodyElements.js'

import { IoMdClose } from "react-icons/io"

const LaunchProjectModal = ({ setShowLaunchModal, projectId }) => {
    const modalRef = useRef(null)

    const closeModal = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''   
            setShowLaunchModal(false)
        }
    }

    const handleCloseModal = () => {
        document.body.style.overflow = ''
        setShowLaunchModal(false)
    }

    const handleLaunchProject = async () => {
        document.body.style.overflow = ''
        await launchProject(projectId)
        window.location.reload()
    }

    return (
        <Wrapper>
            <ModalBg ref={modalRef} onClick={closeModal} style={{ zIndex: 10000 }}>
                <LaunchModal>
                    <IoMdClose onClick={handleCloseModal} style={{ marginBottom: 10, cursor: 'pointer' }} size={20}/>
                    <LaunchText>
                        Are you sure you want to launch your project? You will no longer be able to edit your <b>target fund</b>, <b>equity given</b>, or <b>bounus return</b>. You will be allowed to edit your <b>campaign duration/finish date</b> up until the gauging interest phase is finished and the funding phase begins. 
                    </LaunchText>
                    <ButtonWrapper>
                        <CancelButton onClick={handleCloseModal}>No, I need to go back</CancelButton>
                        <ContinueButton onClick={handleLaunchProject} >Yes, I&apos;m ready</ContinueButton>
                    </ButtonWrapper>
                </LaunchModal>
            </ModalBg>
        </Wrapper>
    )
}

export default LaunchProjectModal