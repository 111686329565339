import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { 
    Wrapper, 
    FaqContainer, 
    FaqElement, 
    Header, 
    Answer, 
    ArrowIcon,
    RiskParagraph,
    LinkTag
} from './FaqElements'
import { RiArrowRightSLine } from 'react-icons/ri'

const faqData = [
    {
        question: "What is Muugen Funding?",
        answer: (
            <>
                Muugen Funding is a platform for investing in and creating film and series projects. It operates through two stages: <Link to="/posts">Gauging Interest</Link> and <Link to="/posts">Funding</Link>.
                <br /><br />
                In the gauging interest phase, we assess support and demand for films. In the funding phase, investors contribute funds in exchange for equity. If the film is successful, backers receive a return on their investment based on their equity and funding agreement with the filmmaker.
                <br /><br />
                Muugen Funding is a Public Benefit Corporation. To learn more about Muugen Funding go to our <Link to="/posts">Blog</Link>.
            </>
        )
    },
    {
        question: "What does Muugen Funding take as a cut?",
        answer: (
            <>
                Muugen Funding is proud to charge NO fees to both creators and investors. However, there may still be fees associated with payment processing.
                <br /><br />
                Muugen Funding also does not take any ownership or equity from your film or series once funding is completed. <Link to="/posts">Learn More</Link>
            </>
        )
    },
    {
        question: "Why do I have to provide my personal information when investing?",
        answer: (
            <>
                Crowdfunding regulations require all funding platforms to work with an escrow agent. Escrow agents, like all financial institutions in the US, must adhere to the policies of the Bank Secrecy Act, which include <a href='https://www.investopedia.com/terms/a/aml.asp' target='_blank' rel='noopener noreferrer' >Anti-Money Laundering</a> (AML) and <a href='https://www.investopedia.com/terms/k/knowyourclient.asp' target='_blank' rel='noopener noreferrer'>Know Your Customer</a> (KYC) checks to ensure there are no fraudulent activities. These measures are in place to protect both investors and project creators, ensuring a secure and transparent funding environment.
                <br /><br />
                We understand that your personal information is highly sensitive. We always use industry-standard encryption when sending your data to the escrow agent (and only the escrow agent), and we never store it in our own database.
            </>
        )
    },
    {
        question: "Can non-US citizens invest?",
        answer: (
            <>
                No, due to different regulations being associated with different regions, non-US citizens cannot currently invest with our platform. We will be sure to update everyone when a new region/country is added.
            </>
        )
    },
    {
        question: "I'm a creator. How do I get started creating a campaign?",
        answer: (
            <>
                To start a campaign, <a href='/sign-up' target='_blank' rel='noopener noreferrer'>sign up on our platform</a> and register as an artist. We will review your work and if invited, you can begin your campaign by following the campaign creation steps. <Link to="/posts">Learn More</Link>
            </>
        )
    }
]

function Faq() {
    const [expandedIndex, setExpandedIndex] = useState(null)

    const navigate = useNavigate()

    const handleClick = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index)
    }

    const handleLinkClick = () => {
        window.scrollTo(0, 0)
        navigate('/posts/what-you-need-to-know-about-regulation-crowdfunding-NARUj8_Q')
    }

    return (
        <Wrapper>
            <Header>
                General Frequently Asked Questions
            </Header>
            <FaqContainer>
                {faqData.map((item, index) => (
                    <React.Fragment key={index}>
                        <FaqElement onClick={() => handleClick(index)}>
                            <div>{item.question}</div>
                            <ArrowIcon expanded={expandedIndex === index}>
                                <RiArrowRightSLine size={20} />
                            </ArrowIcon>
                        </FaqElement>
                        {expandedIndex === index && (
                            <Answer>{item.answer}</Answer>
                        )}
                    </React.Fragment>
                ))}
            </FaqContainer>
            <RiskParagraph>
                Investing in films carries inherent risks, and you may lose your entire investment. Only invest with funds you can afford to lose. <LinkTag onClick={handleLinkClick}>Learn more</LinkTag>.
            </RiskParagraph>
        </Wrapper>
    )
}

export default Faq