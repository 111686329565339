import axios from 'axios'
import { API_URL } from '../../config'

export const getArtistProfile = async (slug) => {
    try {
        const response = await axios.get(`${API_URL}/artist-profile/${slug}`, { withCredentials: true })

        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const followArtist = async (slug, setShowEmailVerificationModal) => {
    try {
        const response = await axios.post(`${API_URL}/investment-profile/follow-artist/me`, { slug }, { withCredentials: true })

        return response.data
    } catch (error) {
        if (error.response?.data?.requiresVerification) {
            setShowEmailVerificationModal(true)
        } else {
            const errorMessage = error.response?.data?.message || 'Failed to follow artist'
            alert(errorMessage)
        }
    }
}