import styled from 'styled-components'

export const VerificationMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    text-align: center;
    height: 300px;
`

export const ResendLink = styled.div`
    margin-top: 10px;
    color: #68B984;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`