import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import NonMainNav from '../NonMainNav'
import {
    Wrapper,
    VerificationMessage,
    CheckboxLink
} from './SignupElements.js'

import { 
    LoginHeader,
    LoginWrapper, 
} from '../Login/LoginElements'

const PendingVerification = () => {
    const location = useLocation()
    const email = location.state?.email
    const justSignedUp = location.state?.justSignedUp

    // Redirect if accessed directly without email
    if (!email || !justSignedUp) {
        return <Navigate to="/" replace />
    }

    return (
        <div>
            <NonMainNav />
            <Wrapper>
                <LoginWrapper>
                    <LoginHeader>Email Verification Required</LoginHeader>
                    <VerificationMessage>
                        <h3>Thanks for signing up!</h3>
                        <p>We&apos;ve sent a verification email to:</p>
                        <p><strong>{email}</strong></p>
                        <p>Please check your inbox and click the verification link to complete your registration.</p>
                    </VerificationMessage>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <p>Didn&apos;t receive the email?</p>
                        <CheckboxLink 
                            onClick={() => window.location.reload()}
                            style={{ cursor: 'pointer' }}
                        >
                            Try signing up again
                        </CheckboxLink>
                    </div>
                </LoginWrapper>
            </Wrapper>
        </div>
    )
}

export default PendingVerification