import React, { useState, useRef, useEffect, useContext, Suspense } from 'react'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import { API_URL } from '../../../../../config'
import { showOrLoadMoreReplies } from '../updatesApi'
import { NoOutlineImage, CaptionTextDisplay } from '../../ProjectStory/StoryElements'

import DOMPurify from 'dompurify'
import LoadingComponent from '../../../../LoadingAsset'
import { ObjectId } from 'bson'
import { formatReadableDate } from '../../../../../helpers/readableDate'
import { AuthContext } from '../../../../../authContext'
import { handleIncrease, handleCancel  } from '../../../../../helpers/commentHelpers'
import { 
    Wrapper,
    Container,
    CloseContainer
} from '../NewUpdateModal/NewUpdateModalElements.js'

import { 
    CreatorContainer,
    Icon,
    IconImage,
    Name,
    DateUploaded,
    IconContainer,
    IconWrapper,
    StyledTbHeart,
    Likes,
    CreatorType
 } from '../UpdatesElements.js'

import {
    AddCommentWrapper,
    AddComment,
    AddButton,
    CancelButton,
    CommentWrapper,
    CommentSectionWrapper,
    CommentsMetadata,
    LoadMoreComments,
    ShowMoreWrapper,
    ShowMoreRepliesWrapper
} from '../../Discussion/DiscussionElements.js'

import { 
    ProjectName, 
    Title, 
    ContentContainer, 
    CommentCountContainer,
    CommentCount,
    Underline,
} from './UpdateModalElements.js'

import {
    VideoFrame,
    VideoWrapper,
} from '../../ProjectStory/StoryElements'

import { EditButton } from '../../Perks/PerkElements'
import { ModalBg } from '../../../Modal/EditModalElements.js'
import { IoClose } from 'react-icons/io5'
import { IoMdArrowDropdownCircle, IoMdArrowDropupCircle } from 'react-icons/io'

import CommentCard from '../../Discussion/CommentCard'
import ChildCommentCard from '../../Discussion/ChildCommentCard'
import EmailVerificationModal from '../../../../EmailVerificationModal'

import { BsArrowReturnRight } from 'react-icons/bs'

import { ImageError } from '../LikesModal/LikesModalElements'

import { FaUser } from 'react-icons/fa'

const EditUpdateModal = React.lazy(() => import('./EditUpdateModal'))
const LikesModal = React.lazy(() => import('../LikesModal'))

function UpdateModal({ 
    setShowUpdateModal, 
    scrollToComments, 
    setScrollToComments, 
    updates, 
    setUpdates, 
    update,
    updateElements, 
    setUpdateElements, 
    projectInfo, 
    updateIndex, 
    commentRef,
    filled,
    handleIconClick,
    setImageError
}) {
    const [isEditMode, setIsEditMode] = useState(false)
    const [comments, setComments] = useState([])
    const [commentsLength, setCommentsLength] = useState(0)
    const [pageCount, setPageCount] = useState(1)
    const [commentLimit, setCommentLimit] = useState(10)
    const [addComment, setAddComment] = useState('')
    const [showCommentButtons, setShowCommentButtons] = useState(false)
    const [commentAdds, setCommentAdds] = useState(0)
    const [showInModalLike, setShowInModalLike] = useState(false)
    const [profileImageError, setProfileImageError] = useState(false)
    const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false)
    const { loggedInUser, setLoggedInUser } = useContext(AuthContext)

    const modalRef = useRef(null)
    const textareaRef = useRef(null)
    const childCommentRef = useRef(null)
    const inputRefs = useRef([])

    const navigate = useNavigate()

    useEffect(() => {
        const source = axios.CancelToken.source()
    
        const getComments = async () => {
            if (projectInfo.project && comments.length === 0) {
                try {
                    const response = await axios.get(`${API_URL}/updates/comments/${update._id}`, {
                        params: { page: pageCount, limit: 10, commentAdds },
                        withCredentials: true,
                        cancelToken: source.token
                    })

                    setComments(response.data.comments.results)
                    setCommentsLength(response.data.comments.arrayLength)
                } catch (error) {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled:', error.message)
                    } else {
                        console.log('Error fetching comments:', error)
                    }
                }
            }
        }
    
        getComments()
    
        // Cleanup function
        return () => {
            source.cancel("Component unmounted: Canceling axios request")
        }
    
        //eslint-disable-next-line
    }, [update])

    useEffect(() => {
        setUpdateElements(updates[updateIndex].updateElements)
        // updates, index, updateElements, setUpdateElements
        //eslint-disable-next-line
    }, [updateIndex])

    useEffect(() => {
        let timeoutId
    
        if (scrollToComments && commentRef.current) {
            timeoutId = setTimeout(() => {
                if (commentRef.current) {
                    commentRef.current.scrollIntoView({ behavior: 'smooth' })
                }
            }, 300)
        }
    
        // Cleanup function
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    
        //eslint-disable-next-line
    }, [scrollToComments])

    useEffect(() => {
        try {
            setUpdates(prevUpdates => {
                const newUpdates = [...prevUpdates]
                newUpdates[updateIndex].updateElements = updateElements
                return newUpdates
            })
        } catch (error) {
            console.error(error)
        }
        //eslint-disable-next-line
    }, [updateElements])

    const closeModalClick = e => {
        if (modalRef.current === e.target) {
            document.body.style.overflow = ''
            setShowUpdateModal(false)
            setScrollToComments(false)
        }
    }

    const closeModalExit = e => {
        document.body.style.overflow = ''   
        setShowUpdateModal(false)
        setScrollToComments(false)
    }

    const handleAddComment = async () => {
        if (!loggedInUser.isEmailVerified) {
            setShowEmailVerificationModal(true)
            return
        }

        if (addComment) {
            let isCreator = projectInfo?.project?.artist._id === loggedInUser?.artist ? true : false
            const isCollaborator = loggedInUser.collaborations.includes(projectInfo.project._id)

            const date = new Date()
            const id = new ObjectId()

            const payload = {
                _id: id,
                projectId: projectInfo.project._id,
                updateId: updates[updateIndex]._id,
                imageFilePath: loggedInUser?.userProfileImage,
                userId: loggedInUser.id,
                username: loggedInUser?.username,
                comment: addComment,
                commentBy: loggedInUser?.displayName,
                isCreator,
                isCollaborator,
                createdAt: date,
            }

            try {
                const response = await axios.post(`${API_URL}/updates/add-comment`, payload, { withCredentials: true })
                setCommentsLength(prev => prev + 1)
                setCommentAdds(prev => prev + 1)
                setComments(array => [response.data, ...array])
                setUpdates(prevUpdates => {
                    return prevUpdates.map((update, index) => {
                        if (index === updateIndex) {
                            // Create a new update object with the incremented comments count
                            return {
                                ...update,
                                comments: (update.comments || 0) + 1  // Use 0 as default if comments is undefined
                            }
                        }
                        return update
                    })
                })
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Unauthorized, navigate to the login page
                    document.body.style.overflow = ''  
                    navigate('/login')
                } else {
                    console.error(error)
                }
            }
        } 
    }

    const handleLoadMore = async () => {
        const updatedPageCount = pageCount + 1
        const response = await axios.get(`${API_URL}/updates/comments/${updates[updateIndex]._id}`, { params: { page: updatedPageCount, limit: 10, commentAdds }, withCredentials: true })

        let data = response.data.comments.results
        setCommentLimit(10)
        setComments(prev => prev.concat(data))
        setPageCount(updatedPageCount)
    }

    const handleShowReplies = async (commentId, index) => {
        const tempArray = [...comments]
    
        // Check if it's the first call to show reply for this comment.
        if (!tempArray[index].showReplies && !tempArray[index].repliesCalled) {
            tempArray[index].pageCount = 1
            if (!tempArray[index].childCommentAdds) {
                tempArray[index].childCommentAdds = 0
            }
            try {
                const response = await showOrLoadMoreReplies(commentId, tempArray[index].pageCount, commentLimit, true, tempArray[index].childCommentAdds)

                tempArray[index].replies = response.data.results
                tempArray[index].repliesCalled = true // Set repliesCalled here after fetching
            } catch (error) {
                console.error("Error fetching replies:", error)
                // Handle error appropriately (e.g., show a message to the user)
            }
            tempArray[index].showReplies = true
        } else {
            // Toggle the visibility of replies without changing repliesCalled
            tempArray[index].showReplies = !tempArray[index].showReplies
        }
    
        setComments(comments.map((comment, idx) => idx === index ? tempArray[index] : comment))
    }    

    const handleLoadMoreReplies = async (index, parentId) => {
        try {
            const updatedComments = comments.map((comment, idx) => {
                if (idx === index) {
                    return {
                        ...comment,
                        pageCount: comment.pageCount + 1
                    }
                }
                return comment
            })
    
            const response = await showOrLoadMoreReplies(
                parentId, 
                updatedComments[index].pageCount, 
                commentLimit, 
                true, 
                updatedComments[index].childCommentAdds
            )
    
            setComments(prevComments => prevComments.map((comment, idx) => {
                if (idx === index) {
                    return {
                        ...comment,
                        replies: [...comment.replies, ...response.data.results]
                    }
                }
                return comment
            }))
        } catch (error) {
            console.error('Error loading more replies:', error)
        }
    }

    let commentMapping

    //@dev loads both comments and child comments (replies).
    if (comments && comments.every(comment => comment && typeof comment._id === 'string')) {
        commentMapping = comments.map((comment, index) => {
            return (
                <div key={comment._id} style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <CommentCard 
                        setCommentAdds={setCommentAdds} 
                        setLimit={setCommentLimit} 
                        setPageCount={setPageCount} 
                        projectInfo={projectInfo} 
                        index={index} 
                        comments={comments} 
                        setComments={setComments} 
                        comment={comment} 
                        childCommentRef={childCommentRef} 
                        setCommentsLength={setCommentsLength} 
                        type={'UPDATE'} 
                        updateId={comment.updateId}
                        setUpdates={setUpdates}
                        route={'updates'}
                        loggedInUser={loggedInUser}
                    />
                {comment.childComments.length > 0 ? <ShowMoreWrapper onClick={() => handleShowReplies(comment._id, index)}>{!comment.showReplies ? <IoMdArrowDropdownCircle size={15} style={{ marginRight: 5 }}/> : <IoMdArrowDropupCircle size={15} style={{ marginRight: 5 }} />}<div>{comment.childComments.length ? comment.childComments.length : null} REPLIES</div></ShowMoreWrapper> : null}
                {comment.replies && comment.showReplies ? comment.replies.map((reply, replyIndex) => {
                    return (
                        <div key={reply._id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <ChildCommentCard 
                                setCommentAdds={setCommentAdds}
                                setLimit={setCommentLimit}
                                setPageCount={setPageCount} 
                                projectInfo={projectInfo}
                                comments={comments} 
                                setComments={setComments} 
                                comment={comment}
                                reply={reply}
                                index={index}
                                replyIndex={replyIndex}
                                setCommentsLength={setCommentsLength}
                                route={'updates'}
                                loggedInUser={loggedInUser}
                            />
                            {replyIndex === comment.replies.length - 1 && comment.childComments.length !== comment.replies.length ? <ShowMoreRepliesWrapper onClick={() => handleLoadMoreReplies(index, comment._id)}><BsArrowReturnRight size={15} style={{ marginRight: 5 }} /> Show more replies</ShowMoreRepliesWrapper> : null}
                        </div>
                    )
                }) : null}
                </div>
            )
        })
    }

    const handleArtistProfileClick = (artistSlug) => {
        document.body.style.overflow = ''
        navigate(`/artist-profile/${artistSlug}`)
    }

    const handleCountClick = () => {
        document.body.style.overflow = 'hidden'
        setShowInModalLike(true)
    }

    return (
        <ModalBg ref={modalRef} onClick={closeModalClick}>
            <Wrapper>
                {showEmailVerificationModal && <EmailVerificationModal show={showEmailVerificationModal} setShowEmailVerificationModal={setShowEmailVerificationModal} />}
                <CloseContainer onClick={closeModalExit}>
                    <IoClose size={30} />
                </CloseContainer>
                <Container>
                {showInModalLike && (
                    <Suspense fallback={<LoadingComponent />}>
                        <LikesModal 
                            loggedInUser={loggedInUser} 
                            setLoggedInUser={setLoggedInUser} 
                            updateId={updates?.[updateIndex]?._id} 
                            setShowInModalLike={setShowInModalLike} 
                        />
                    </Suspense>
                )}
                {!isEditMode &&    
                    <div>
                        {(loggedInUser?.artist === projectInfo?.project?.artist._id || projectInfo?.project?.authorizedCollaborators?.includes(loggedInUser?.id)) && <EditButton onClick={() => setIsEditMode(true)}>Edit Mode</EditButton>}
                    </div>
                }
                {isEditMode ? (
                    <Suspense fallback={<LoadingComponent />}>
                        <EditUpdateModal 
                            updates={updates} 
                            setUpdates={setUpdates} 
                            updateIndex={updateIndex} 
                            update={updates[updateIndex]} 
                            setUpdateElements={setUpdateElements} 
                            isEditMode={isEditMode} 
                            setIsEditMode={setIsEditMode} 
                            projectInfo={projectInfo} 
                            inputRefs={inputRefs}
                            setImageError={setImageError}
                            setShowUpdateModal={setShowUpdateModal}
                        />
                    </Suspense>
                ) :
                <>
                    <ProjectName>{projectInfo?.project?.projectName}</ProjectName>
                    <Title>{updates?.[updateIndex]?.title}</Title>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <CreatorContainer onClick={() => handleArtistProfileClick(projectInfo?.project?.artist?._id)}>
                            {update.user.profilePictureUrl && !profileImageError ?
                                <Icon>
                                    <IconImage src={`${update.user.profilePictureUrl}`} onError={() => setProfileImageError(true)}/>
                                </Icon>
                            :
                                <ImageError>
                                    <FaUser />
                                </ImageError>
                            }
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex' }}>
                                    <Name>{projectInfo.project.artist.artistName}</Name>
                                    <DateUploaded>published on {formatReadableDate(updates?.[updateIndex]?.timeCreated)}</DateUploaded>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CreatorType>{update?.user?.artist === projectInfo?.project?.artist?._id ? 'Creator' : 'Collaborator'}</CreatorType>
                                </div>
                            </div>
                        </CreatorContainer>
                        <IconContainer style={{ flexDirection: 'row' }}>
                            <IconWrapper>
                                <StyledTbHeart size={25} onClick={() => handleIconClick(updateIndex)} className='heart-transition' fill={filled} color={filled === 'red' ? 'red' :  undefined}/>
                            </IconWrapper>
                            <Likes onClick={handleCountClick}>{update?.likeCount}</Likes>
                        </IconContainer>
                    </div>
                    <Underline />
                    <ContentContainer>
                        {updateElements?.map((element, i) => {
                            switch (element.type) {
                                case "text":
                                    return <p style={i > 0 ? { marginTop: 10, marginBottom: 10, fontFamily: "'DM Sans', serif" } : { fontFamily: "'DM Sans', serif" }} key={element._id} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.content) }} />
                                case "title":
                                    return <h1 style={{ fontSize: 30, marginTop: 10, fontFamily: "'DM Sans', serif", fontWeight: 500 }} key={element._id}>{element.content}</h1>
                                case "image":
                                    return (
                                        <div key={element._id} style={{ marginBottom: 10, marginTop: 10 }}>
                                            <NoOutlineImage
                                                key={element._id}
                                                src={element.content}
                                                alt={element.caption || 'Story image'}
                                            />
                                            {element.caption && (
                                                <CaptionTextDisplay>{element.caption}</CaptionTextDisplay>
                                            )}
                                        </div>
                                        )
                                case "embed": {
                                    const extractSrc = (iframeString) => {
                                        const match = iframeString.match(/src="([^"]+)"/)
                                        return match ? match[1] : null
                                    }

                                    const src = extractSrc(element.content)
                                    return (
                                        <VideoWrapper key={element._id}>
                                            <VideoFrame src={src}>
                                            </VideoFrame>
                                        </VideoWrapper>
                                    ) 
                                }
                                default:
                                    return null
                            }
                        })}
                    </ContentContainer>
                    <ContentContainer style={{ alignItems: 'flex-start', marginTop: 100 }} >
                        <CommentCountContainer ref={commentRef}>
                            <CommentCount>{comments.length} Comments</CommentCount>
                        </CommentCountContainer>
                        <AddCommentWrapper style={{ width: '100%' }}>
                            <AddComment style={{ width: '100%' }} ref={textareaRef} onInput={() => handleIncrease(textareaRef)} onChange={e => setAddComment(e.target.value)} onClick={() => setShowCommentButtons(true)} placeholder='Add a comment...' />
                            {showCommentButtons ? 
                                <div style={{ display: 'flex' }} >
                                    <CancelButton onClick={() => handleCancel(textareaRef, setAddComment, setShowCommentButtons)}>Cancel</CancelButton>
                                    <AddButton onClick={handleAddComment} style={addComment ? {} : { background: '#D8D8D8', cursor: 'default' }}>Comment</AddButton>
                                </div>
                                : null
                            }
                        </AddCommentWrapper>
                        <CommentWrapper>
                            <CommentSectionWrapper >
                                {commentMapping}
                            </CommentSectionWrapper>
                            <CommentsMetadata >Showing {comments.length} of {commentsLength}</CommentsMetadata>
                            {comments.length !== commentsLength ? <LoadMoreComments onClick={handleLoadMore}>Load More</LoadMoreComments> : null}
                        </CommentWrapper>
                    </ContentContainer>    
                </>
                }
                </Container>
            </Wrapper>
        </ModalBg>
    )
}

export default UpdateModal