import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from '../../config'
import { useMediaQuery } from 'react-responsive'
// import { getCookie } from '../../helpers/getCookie'

import { 
    Nav, 
    NavButton, 
    NavMenu, 
    NavDropdown,
    IconWrapper,
    NotificationWrapper, 
    NotificationNumber,
    SearchInput,
    SignupButton
} from './NonMainNavElements'

import {
    SearchButton,
    SearchInputWrapper,
    Separator,
} from '../Navbar/NavbarElements.js'

import NotificationModal from './NotificationModal'
import UserProfileModal from './UserProfileModal'
import DiscoverModal from '../NonMainNav/DiscoverModal'
import SearchResults from '../Navbar/SearchResults.js'
import MobileMenu from '../Navbar/MobileMenu'
import EmailVerificationModal from '../EmailVerificationModal'

import { PiUserCircleThin } from 'react-icons/pi'
import { IoIosNotificationsOutline, IoIosNotifications } from 'react-icons/io'
import { FaUserCircle, FaBars } from 'react-icons/fa'
import { GoChevronDown, GoSearch } from 'react-icons/go'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getCookie } from '../../helpers/getCookie'

const Navbar = () => {
    // Add navigate hook near your other hooks at the top of the component
    const navigate = useNavigate()

    const queryClient = useQueryClient()

    const { data: userData } = useQuery({
        queryKey: ['user'],
        queryFn: async () => {
            const token = getCookie('tokenPresence')
            if (!token) return { loggedIn: false }
            const response = await axios.get(`${API_URL}/login/me`, { withCredentials: true })
            const unreadCount = response.data.notifications.filter(n => !n.read).length
            return { ...response.data, loggedIn: true, unreadNotificationsCount: unreadCount }
        },
        staleTime: 1000 * 60 * 5, // 5 minutes, adjust as needed
    })

    const loggedInUser = userData

    const [showProfileModal, setShowProfileModal] = useState(false)
    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [showDiscoverDropdown, setShowDiscoverDropdown] = useState(false)
    const [showSearchInput, setShowSearchInput] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [page, setPage] = useState(1)
    const [showSearchResults, setShowSearchResults] = useState(false)
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)
    const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false)

    const modalRef = useRef(null)
    const notificationModalRef = useRef(null)
    const iconWrapperRef = useRef(null)
    const notificationIconRef = useRef(null)
    const discoverRef = useRef(null)
    const discoverModalRef = useRef(null)
    const searchInputRef = useRef(null)
    const searchResultsRef = useRef(null)

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    useEffect(() => {
        if (userData?.notifications) {
            setUnreadNotificationsCount(userData.unreadNotificationsCount)
        }
    }, [userData])

    useEffect(() => {
        const handleResize = () => {
            if (!isMobile) {
                setShowMobileMenu(false)
                document.body.style.overflow = 'auto'
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [isMobile])

    useEffect(() => {
        if (loggedInUser?.notifications) {
            const unreadCount = loggedInUser.notifications.filter(n => !n.read).length
            setUnreadNotificationsCount(unreadCount)
        }
    }, [loggedInUser])

    const handleNotificationClick = async () => {
        setShowNotificationModal(!showNotificationModal)
        if (!showNotificationModal && unreadNotificationsCount > 0) {
            try {
                await axios.post(`${API_URL}/notifications/mark-as-read`, {}, { withCredentials: true })
                setUnreadNotificationsCount(0)
                queryClient.invalidateQueries(['user'])
            } catch (error) {
                console.error('Failed to mark notifications as read:', error)
            }
        }
    }

    const handleSearchClick = () => {
        setShowSearchInput(!showSearchInput)
        if (!showSearchInput) {
            setTimeout(() => {
                searchInputRef.current.focus()
            }, 0)
        }
    }

    const onChangeHandler = (e) => {
        setShowSearchResults(true)
        setSearchQuery(e.target.value)
        setPage(1)
    }

    const handleClickOutside = (event) => {
        if (
            searchResultsRef.current &&
            !searchResultsRef.current.contains(event.target) &&
            searchInputRef.current &&
            !searchInputRef.current.contains(event.target)
        ) {
            setShowSearchResults(false)
        }

        if (iconWrapperRef.current && iconWrapperRef.current.contains(event.target)) {
            setShowNotificationModal(false)
            setShowDiscoverDropdown(false)
            return
        }
        if (notificationIconRef.current && notificationIconRef.current.contains(event.target)) {
            setShowProfileModal(false)
            setShowDiscoverDropdown(false)
            return
        }
        if (discoverRef.current && discoverRef.current.contains(event.target)) {
            setShowProfileModal(false)
            setShowNotificationModal(false)
            return
        }
        if (discoverModalRef.current && !discoverModalRef.current.contains(event.target)) {
            setShowDiscoverDropdown(false)
        }
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowProfileModal(false)
        }
        if (notificationModalRef.current && !notificationModalRef.current.contains(event.target)) {
            setShowNotificationModal(false)
        }
        if (discoverModalRef.current && !discoverModalRef.current.contains(event.target)) {
            setShowDiscoverDropdown(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleCreateProjectClick = () => {
        if (!loggedInUser.isEmailVerified) {
            setShowEmailVerificationModal(true)
            return
        }

        if (loggedInUser?.loggedIn && loggedInUser?.artist) {
            navigate('/create-project')
        } else if (loggedInUser?.loggedIn && !loggedInUser?.artist) {
            navigate('/artist-registry')
        } else if (!loggedInUser?.loggedIn) {
            navigate('/login')
        }
    }

    const renderLogin = loggedInUser?.loggedIn
        ? 
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 10 }}>
            {showNotificationModal && <NotificationModal ref={notificationModalRef} setShowNotificationModal={setShowNotificationModal} notifications={[]} />}
            {showProfileModal && <UserProfileModal ref={modalRef} setShowProfileModal={setShowProfileModal} />}
            {showSearchInput && (
                <SearchInputWrapper style={{ marginRight: 0 }}>
                    <GoSearch size={18} style={{ marginRight: 5 }} />
                    <SearchInput 
                        onClick={() => setShowSearchResults(true)} 
                        onChange={onChangeHandler} 
                        ref={searchInputRef} 
                        value={searchQuery}
                        placeholder="Search..." 
                    />
                </SearchInputWrapper>
            )}
            {!showSearchInput && (
                <SearchButton style={{ color: 'black' }} onClick={handleSearchClick}>
                    <GoSearch style={{ marginRight: 5 }} />
                    Search
                </SearchButton>
            )}
            <NavButton onClick={() => navigate('/posts')}>
                Blog
            </NavButton>
            <Separator style={{ backgroundColor: 'lightgray' }}/>
            <NotificationWrapper >
                <IconWrapper ref={notificationIconRef} onClick={handleNotificationClick}>
                    {!showNotificationModal ? <IoIosNotificationsOutline size={29} /> : <IoIosNotifications size={29} />}
                    {unreadNotificationsCount > 0 && <NotificationNumber>{unreadNotificationsCount}</NotificationNumber>}
                </IconWrapper>
            </NotificationWrapper>
            <IconWrapper ref={iconWrapperRef} onClick={() => setShowProfileModal(!showProfileModal)} >
                {!showProfileModal ? <PiUserCircleThin size={30} style={{ cursor: 'pointer' }}/> : <FaUserCircle size={25} style={{ cursor: 'pointer' }}/>}
            </IconWrapper>
        </div>
        : 
        <React.Fragment>
            {showSearchInput && (
                <SearchInputWrapper style={{ width: 'auto', marginRight: 0 }}>
                    <GoSearch size={18} style={{ marginRight: 5 }} />
                    <SearchInput 
                        onClick={() => setShowSearchResults(true)} 
                        onChange={onChangeHandler} 
                        ref={searchInputRef} 
                        value={searchQuery}
                        placeholder="Search..." 
                    />
                </SearchInputWrapper>
            )}
            {!showSearchInput && (
                <SearchButton style={{ color: 'black' }} onClick={handleSearchClick}>
                    <GoSearch style={{ marginRight: 5 }} />
                    Search
                </SearchButton>
            )}
            <NavButton onClick={() => navigate('/posts')}>
                Blog
            </NavButton>
            <Separator style={{ backgroundColor: 'black' }} />
            <NavButton onClick={() => navigate('/login')} >
                Login
            </NavButton>
            <SignupButton to="/sign-up">
                Sign Up
            </SignupButton>
        </React.Fragment>

    const renderDesktopNavbar = (
        <>
            <NavMenu style={{ justifyContent: 'flex-start' }} >
                <div style={{ display: 'flex', position: 'relative' }}>
                    {showDiscoverDropdown && <DiscoverModal ref={discoverModalRef} setShowDiscoverDropdown={setShowDiscoverDropdown} />}
                    <NavDropdown ref={discoverRef} onClick={() => setShowDiscoverDropdown(!showDiscoverDropdown)}>
                        Discover
                        <GoChevronDown 
                            style={{ 
                                fontSize: 20, 
                                marginLeft: 5,
                                transform: showDiscoverDropdown ? 'rotate(-180deg)' : 'rotate(0deg)',
                            }}
                        />
                    </NavDropdown>
                    <NavButton onClick={handleCreateProjectClick} style={{ padding: 0 }} >
                        {loggedInUser?.loggedIn && loggedInUser?.artist ? 'Create a Project' : 'Artist Register'}
                    </NavButton>
                </div>
            </NavMenu>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <NavButton onClick={() => navigate('/')} style={{ padding: 0 }} >
                    <h1>muugen</h1>
                </NavButton>
            </div>
            {/* <Bars /> */}
            <NavMenu>
                {renderLogin}
            </NavMenu>
        </>
    )

    const renderMobileNavbar = (
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          width: '100%', 
          padding: '10px' 
        }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            width: '100%' 
          }}>
            <NavButton onClick={() => navigate('/')} style={{ fontSize: '20px', fontWeight: 'bold', flexShrink: 0 }}>
              muugen
            </NavButton>
            
            <SearchInputWrapper style={{ 
              flex: 1, 
              margin: '0 10px',
              maxWidth: '50%'
            }}>
              <GoSearch size={18} style={{ marginRight: 5 }} />
              <SearchInput
                onClick={() => setShowSearchResults(true)}
                onChange={onChangeHandler}
                ref={searchInputRef}
                value={searchQuery}
                placeholder="Search..."
                style={{ 
                  borderBottom: '1px solid black',
                  fontSize: '14px',
                  width: 'calc(100% - 23px)' // Adjust for the search icon
                }}
              />
            </SearchInputWrapper>
      
            <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
              {loggedInUser?.loggedIn ? (
                <IconWrapper 
                  ref={iconWrapperRef} 
                  onClick={() => setShowProfileModal(!showProfileModal)}
                  style={{ width: '40px', height: '40px', marginRight: '5px' }}
                >
                  {!showProfileModal ? 
                    <PiUserCircleThin size={28} style={{ cursor: 'pointer' }} /> : 
                    <FaUserCircle color='black' size={24} style={{ cursor: 'pointer' }} />
                  }
                </IconWrapper>
              ) : (
                <SignupButton 
                  to='/sign-up' 
                  style={{ 
                    minWidth: 60, 
                    fontSize: '12px', 
                    padding: '6px 8px',
                    marginRight: '5px'
                  }}
                >
                  Sign Up
                </SignupButton>
              )}
              <IconWrapper 
                onClick={() => setShowMobileMenu(!showMobileMenu)}
                style={{ width: '40px', height: '40px' }}
              >
                <FaBars size={20} style={{ cursor: 'pointer' }} />
              </IconWrapper>
            </div>
          </div>
      
          {showMobileMenu && (
            <MobileMenu
              showMobileMenu={showMobileMenu}
              setShowMobileMenu={setShowMobileMenu}
              isMobile={isMobile}
              loggedInUser={loggedInUser}
            />
          )}
          
          {showProfileModal && (
            <UserProfileModal 
              ref={modalRef} 
              setShowProfileModal={setShowProfileModal} 
            />
          )}
          
          {showDiscoverDropdown && (
            <DiscoverModal 
              ref={discoverModalRef} 
              setShowDiscoverDropdown={setShowDiscoverDropdown} 
            />
          )}
        </div>
    )

    return (
        <div style={{ display: 'flex', justifyContent: 'center', borderBottom: '1px solid #eee' }} >
            {showEmailVerificationModal && <EmailVerificationModal show={showEmailVerificationModal}  setShowEmailVerificationModal={setShowEmailVerificationModal}/>}
            <Nav>
                {isMobile ? renderMobileNavbar : renderDesktopNavbar}
            </Nav>
            <div style={{ position: 'absolute', top: 50, left: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
                {searchQuery.length > 0 && showSearchResults && <SearchResults searchQuery={searchQuery} page={page} setPage={setPage} searchResultsRef={searchResultsRef} />}
            </div>
        </div>
    )
}

export default Navbar