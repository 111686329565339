import './App.css'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import axios from 'axios'
import { API_URL } from './config'
import { getCookie } from './helpers/getCookie'
import ScrollToTop from './ScrollTopTop'
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { AuthProvider } from './authContext'

// Import all your components
import Layout from './Layout'
import Home from './pages'
import ArtistProfile from './pages/ArtistProfile'
import UserProfile from './pages/UserProfile'
import ProjectProfile from './pages/ProjectProfile'
import Posts from './pages/Posts'
import Post from './pages/Post'
import Login from './pages/Login'
import Signup from './pages/Signup'
import ForgotPassword from './pages/ForgotPassword'
import ArtistRegistry from './pages/ArtistRegistry'
import CreateProject from './pages/CreateProject'
import InvestProject from './pages/InvestProject'
import InvestmentProfile from './pages/InvestmentProfile'
import ResetPassword from './pages/ResetPassword'
import Discover from './pages/Discover'
import DiscoverArtists from './pages/DiscoverArtists'
import DiscoverUsers from './pages/DiscoverUsers'
import Admin from './pages/Admin/Admin'
import AdminLogin from './pages/Admin/AdminLogin'
import AdminProjects from './pages/Admin/AdminProjects'
import AdminPosts from './pages/Admin/AdminPosts'
import AdminAcceptRejectList from './pages/Admin/AdminAcceptRejectList'
import AdminArtistWaitlist from './pages/Admin/AdminArtistWaitlist'
import AdminArtists from './pages/Admin/AdminArtists'
import AdminCreatePost from './pages/Admin/AdminCreatePost'
import ProtectedArtistRoute from './components/ProtectedArtistRoute'
import TermsOfService from './pages/Legal/TermsOfService'
import PrivacyPolicy from './pages/Legal/PrivacyPolicy'
import Cookies from './pages/Legal/Cookies'
import EmailVerified from './components/Signup/EmailVerified'
import PendingVerification from './components/Signup/PendingVerification'

const queryClient = new QueryClient()

function AppContent() {
  const fetchUser = async () => {
    const token = getCookie('tokenPresence')
    const userType = getCookie('userType')
    if (!token) return { loggedIn: false }
    if (userType === 'admin') return { loggedIn: false }
    try {
      const response = await axios.get(`${API_URL}/login/me`, { withCredentials: true })
      return response.data ? { ...response.data, loggedIn: true } : { loggedIn: false }
    } catch (error) {
      console.error('Error fetching user data:', error)
      return { loggedIn: false }
    }
  }

  const { data: userData, isLoading } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    enabled: !!getCookie('tokenPresence'),
    staleTime: 0,
    cacheTime: 1000 * 60 * 5,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true
  })

  if (isLoading) {
    return <></>
  }

  return (
    <AuthProvider initialUser={userData}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Layout><Home /></Layout>} />
          <Route path='/project-profile/:projectId' element={<Layout><ProjectProfile /></Layout>} />
          <Route path='/project-profile/:projectId/invest-in-project' element={<InvestProject />} />
          <Route path='/artist-registry' element={<Layout><ArtistRegistry /></Layout>} />
          <Route path='/login' element={<Layout><Login /></Layout>} />
          <Route path='/sign-up' element={<Layout><Signup /></Layout>} />
          <Route path='/password/reset' element={<Layout><ForgotPassword /></Layout>} />
          <Route 
            path='/create-project' 
            element={
              <ProtectedArtistRoute>
                <CreateProject />
              </ProtectedArtistRoute>
            } 
          />
          <Route path='/artist-profile/:slug' element={<Layout><ArtistProfile /></Layout>} />
          <Route path='/user-profile/:username' element={<Layout><UserProfile /></Layout>} />
          <Route path='/my-investment-profile' element={<Layout><InvestmentProfile /></Layout>} />
          <Route path='/reset-password' element={<Layout><ResetPassword /></Layout>} />
          <Route path='/discover' element={<Layout><Discover /></Layout>} />
          <Route path='/discover-artists' element={<Layout><DiscoverArtists /></Layout>} />
          <Route path='/discover-investors' element={<Layout><DiscoverUsers /></Layout>} />
          <Route path='/admin/dashboard' element={<Admin />} />
          <Route path='/admin/login' element={<AdminLogin />} />
          <Route path='/admin/projects' element={<AdminProjects />} />
          <Route path='/admin/posts' element={<AdminPosts />} />
          <Route path='/admin/artist-waitlist' element={<AdminArtistWaitlist />} />
          <Route path='/admin/artist-acceptance' element={<AdminAcceptRejectList />} />
          <Route path='/admin/artists' element={<AdminArtists />} />
          <Route path='/admin/create-post' element={<AdminCreatePost />} />
          <Route path='/posts' element={<Layout><Posts /></Layout>} />
          <Route path='/posts/:slug' element={<Layout><Post /></Layout>} />
          <Route path='/legal/terms' element={<Layout><TermsOfService /></Layout>} />
          <Route path='/legal/privacy-policy' element={<Layout><PrivacyPolicy /></Layout>} />
          <Route path='/legal/cookies' element={<Layout><Cookies /></Layout>} />
          <Route path='/sign-up/email-verified' element={<Layout><EmailVerified /></Layout>} />
          <Route path='/sign-up/pending-verification' element={<Layout><PendingVerification /></Layout>} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContent />
    </QueryClientProvider>
  )
}

export default App