import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../authContext'
import axios from 'axios'
import { API_URL } from '../../config'
import NonMainNav from '../NonMainNav'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useQueryClient } from '@tanstack/react-query'

import {
    SignupError,
    SignupField,
    InputWrapper,
    InputLabel,
    CheckboxText,
    CheckboxLink,
    PrivacyPolicyText,
    // PasswordStrengthBar,
    // PasswordStrengthText,
    ErrorListWrapper,
    ErrorList,
    ErrorListItem,
    VerificationMessage
} from './SignupElements'

import { 
    Wrapper, 
    LoginHeader,
    LoginWrapper, 
    LoginButton, 
    PasswordWrapper,
    PasswordInput,
    EyeIcon,
} from '../Login/LoginElements'

function SignupComponent() {
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [passwordError, setPasswordError] = useState('')
    const [reenterPassword, setReenterPassword] = useState('')
    const [showReenterPassword, setShowReenterPassword] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [signupError, setSignupError] = useState(false)
    // const [passwordStrength, setPasswordStrength] = useState('')
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        lowercase: false,
        uppercase: false,
        number: false,
        specialChar: false
    })
    const [formErrors, setFormErrors] = useState({
        username: '',
        email: '',
        password: '',
        reenterPassword: '',
    })
    const [showVerification, setShowVerification] = useState(false)
    const [verificationEmail, setVerificationEmail] = useState('')

    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { loggedInUser } = useContext(AuthContext)

    const handleTermsClick = () => {
        navigate('/legal/terms')
    }

    const handlePrivacyClick = () => {
        navigate('/legal/privacy-policy')
    }

    const handlePasswordChange = (event) => {
        const { name, value } = event.target
        setPassword(value)
        // setPasswordStrength(checkPasswordStrengthColorCode(value))
        setFormErrors({ ...formErrors, [name]: '' })
        
        // Update password requirements
        setPasswordRequirements({
            minLength: value.length >= 8,
            lowercase: /[a-z]/.test(value),
            uppercase: /[A-Z]/.test(value),
            number: /[0-9]/.test(value),
            specialChar: /[\W_]/.test(value)
        })
        
        if (isFormSubmitted && value === reenterPassword) {
            setPasswordError('')
        }
    }

    const handleReenterPasswordChange = (event) => {
        const { name, value } = event.target
        setReenterPassword(value)
        setFormErrors({ ...formErrors, [name]: '' })
        if (isFormSubmitted && value === password) {
            setPasswordError('')
        }
    }

    const checkPasswordStrength = (password) => {
        if (password.length < 8) return 'Password must be at least 8 characters long.'
        if (!/[a-z]/.test(password)) return 'Password must contain at least one lowercase letter.'
        if (!/[A-Z]/.test(password)) return 'Password must contain at least one uppercase letter.'
        if (!/[0-9]/.test(password)) return 'Password must contain at least one number.'
        if (!/[\W_]/.test(password)) return 'Password must contain at least one special character.'
        return ''
    }    

    const handleInputChange = (event, setInfo) => {
        const { name, value } = event.target

        setFormErrors({ ...formErrors, [name]: '' })
        if (name === 'username') {
            setUsername(value.toLowerCase())
        } else if (name === 'email') {
            setEmail(value)
        }
        setInfo(value)
    }

    const handleSignup = async e => {
        e.preventDefault()
        setIsFormSubmitted(true)

        let errors = {}

        if (!username.trim()) errors.username = 'Username is required.'
        if (!email.trim()) errors.email = 'Email is required.'
        if (!password.trim()) errors.password = 'Password is required.'
        if (!reenterPassword.trim()) errors.reenterPassword = 'Please re-enter your password.'

        if (password !== reenterPassword) {
            setPasswordError('Passwords do not match')
            return
        } else {
            setPasswordError('')
        }

        setFormErrors(errors)

        if (Object.keys(errors).length > 0) {
            return
        }

        const passwordStrengthMessage = checkPasswordStrength(password)
        if (passwordStrengthMessage) {
            setPasswordError(passwordStrengthMessage)
            return
        }

        const payload = {
            username,
            email,
            password,
        }

        try {
            const response = await axios.post(`${API_URL}/signup`, payload, { withCredentials: true })

            if (response.data) {
                if (!response.data.error) {
                    // Store email for verification message
                    setVerificationEmail(email)
                    // Show verification message
                    setShowVerification(true)
                    // Clear form
                    setUsername('')
                    setPassword('')
                    setReenterPassword('')
                    // Update auth context and queries as before
                    // queryClient.invalidateQueries(['user'])
                    // queryClient.refetchQueries(['user'])
                    queryClient.setQueryData(['user'], {
                        ...response.data,
                        loggedIn: true,
                        unreadNotificationsCount: 0, // Since it's a new user
                        notifications: []  // Since it's a new user
                    })
                } else {
                    setSignupError(response.data.error)
                }
            }
        } catch (error) {
            console.log(error)
            setSignupError('An error occurred during signup. Please try again.')
        }

        try {
            const response = await axios.post(`${API_URL}/signup`, payload, { withCredentials: true })
    
            if (response.data) {
                if (!response.data.error) {
                    // Instead of showing verification message, navigate to dedicated verification page
                    navigate('/sign-up/pending-verification', { 
                        state: { 
                            email: email,
                            justSignedUp: true 
                        }
                    })
                } else {
                    setSignupError(response.data.error)
                }
            }
        } catch (error) {
            console.error(error)
            setSignupError('An error occurred during signup. Please try again.')
        }
    }

    const handleResendVerification = async () => {
        try {
            const response = await axios.post(`${API_URL}/signup/resend-verification-sign-up`, { email })

            console.log(response)
        } catch (e) {
            console.error(e)
        }
    }

    if (showVerification) {
        return (
            <div>
                <NonMainNav loggedInUser={loggedInUser} />
                <Wrapper>
                    <LoginWrapper>
                        <LoginHeader>Email Verification Required</LoginHeader>
                        <VerificationMessage>
                            <h3>Thanks for signing up!</h3>
                            <p>We&apos;ve sent a verification email to:</p>
                            <p><strong>{verificationEmail}</strong></p>
                            <p>Please check your inbox and click the verification link to complete your registration.</p>
                        </VerificationMessage>
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <p>Didn&apos;t receive the email?</p>
                            <CheckboxLink 
                                onClick={handleResendVerification}
                                style={{ cursor: 'pointer' }}
                            >
                                Resend Verification Email
                            </CheckboxLink>
                        </div>
                    </LoginWrapper>
                </Wrapper>
            </div>
        )
    }

    return (
        <div>
            <NonMainNav loggedInUser={loggedInUser} />
            <Wrapper>
                <LoginWrapper>
                    <LoginHeader>Muugen Invest</LoginHeader>
                    <InputWrapper>
                        <InputLabel style={formErrors.username || signupError ? { color: 'red' } : {}}>Username</InputLabel>
                        <SignupField 
                            style={formErrors.username || signupError ? { borderColor: 'red' } : {}} 
                            onChange={event => handleInputChange(event, setUsername)} 
                            type='text' 
                            name='username'
                            value={username}
                            placeholder='Create your username.'
                        />
                        {formErrors.username && <SignupError>{formErrors.username}</SignupError>}
                        {signupError && <SignupError>{signupError}</SignupError>}
                    </InputWrapper>
                    <InputWrapper>
                        <InputLabel style={formErrors.email ? { color: 'red' } : {}}>Email Address</InputLabel>
                        <SignupField 
                            style={formErrors.email ? { borderColor: 'red' } : {}} 
                            onChange={event => handleInputChange(event, setEmail)} 
                            type='text' 
                            name='email'
                            value={email}
                            placeholder='Enter your email address.'
                        />
                        {formErrors.email && <SignupError>{formErrors.email}</SignupError>}
                    </InputWrapper>
                    <PasswordWrapper style={{ marginTop: 5 }} >
                        <InputLabel style={passwordError || formErrors.password ? { color: 'red' } : {}}>Password</InputLabel>
                        <PasswordInput 
                            style={passwordError || formErrors.password ? { borderColor: 'red' } : {}} 
                            name='password'
                            onChange={event => handlePasswordChange(event)}  
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Create a password' 
                        />
                        <EyeIcon style={{ marginTop: 5 }} onClick={() => setShowPassword(!showPassword)}>
                            {!showPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                        </EyeIcon>
                        {formErrors.password && <SignupError>{formErrors.password}</SignupError>}
                    </PasswordWrapper>
                    <PasswordWrapper style={{ marginTop: 5 }} >
                        <InputLabel style={passwordError || formErrors.reenterPassword ? { color: 'red' } : {}}>Reenter Password</InputLabel>   
                        <PasswordInput 
                            style={passwordError || formErrors.reenterPassword ? { borderColor: 'red' } : {}} 
                            onChange={event => handleReenterPasswordChange(event)} 
                            name='reenterPassword'
                            type={showReenterPassword ? 'text' : 'password'}
                            placeholder='Confirm your password' 
                        />
                        <EyeIcon style={{ marginTop: 5 }} onClick={() => setShowReenterPassword(!showReenterPassword)}>
                            {!showReenterPassword ? <AiFillEyeInvisible size={20} /> : <AiFillEye size={20} />}
                        </EyeIcon>
                        {formErrors.reenterPassword && <SignupError>{formErrors.reenterPassword}</SignupError>}
                    </PasswordWrapper>
                    {passwordError && <SignupError>{passwordError}</SignupError>}
                    
                    <ErrorListWrapper>
                        <ErrorList>
                            <ErrorListItem isMet={passwordRequirements.minLength}>Password must be at least 8 characters long.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.lowercase}>Password must contain at least one lowercase letter.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.uppercase}>Password must contain at least one uppercase letter.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.number}>Password must contain at least one number.</ErrorListItem>
                            <ErrorListItem isMet={passwordRequirements.specialChar}>Password must contain at least one special character.</ErrorListItem>
                        </ErrorList>
                    </ErrorListWrapper>

                    <PrivacyPolicyText>By clicking sign up you are agreeing to our <CheckboxLink onClick={handleTermsClick}>Terms Of Service</CheckboxLink> and <CheckboxLink onClick={handlePrivacyClick} >Privacy Policy</CheckboxLink></PrivacyPolicyText>
                    <LoginButton onClick={handleSignup}>Sign Up</LoginButton>
                </LoginWrapper>
                <LoginWrapper>
                    <CheckboxText>Have an account? <CheckboxLink onClick={() => navigate('/login')}>Log in</CheckboxLink> instead.</CheckboxText>
                </LoginWrapper>
            </Wrapper>
        </div>
    )
}

export default SignupComponent