import React, { useState } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'
import styled from 'styled-components'
import { 
    VerificationMessage, 
    ResendLink 
} from './ModalElements'
import {
    ModalBg,
    Modal,
} from '../ArtistProfile/Modal/ProfileEditModalElements.js'

// Add some new styled components
const StatusMessage = styled.p`
    margin-top: 15px;
    color: ${props => props.error ? '#ff4444' : '#68B984'};
    font-size: 14px;
`

const CloseButton = styled.button`
    position: absolute;
    top: 0px;
    right: 20px;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #333;
`

const EmailVerificationModal = ({ show, setShowEmailVerificationModal, preventClose = false }) => {
    const [isResending, setIsResending] = useState(false)
    const [resendMessage, setResendMessage] = useState('')
    const [isError, setIsError] = useState(false)

    const handleResendVerification = async () => {
        setIsResending(true)
        setResendMessage('')
        setIsError(false)

        try {
            const response = await axios.post(`${API_URL}/signup/resend-verification`, {}, { withCredentials: true })

            setResendMessage(response.data.message)
            setIsError(!response.data.success)
        } catch (error) {
            setIsError(true)
            setResendMessage(
                error.response?.data?.message || 
                'Failed to resend verification email. Please try again.'
            )
            console.error('Verification email error:', error)
        } finally {
            setIsResending(false)
        }
    }

    const handleClose = () => {
        if (!preventClose) {
            setShowEmailVerificationModal(false)
        }
    }

    if (!show) return null

    return (
        <ModalBg style={{ zIndex: 10000 }}>
            <Modal>
                {!preventClose && (
                    <CloseButton onClick={handleClose}>&times;</CloseButton>
                )}
                <VerificationMessage>
                    <h3>Email Verification Required</h3>
                    <p>Please verify your email address to access this feature.</p>
                    <ResendLink
                        onClick={handleResendVerification}
                        disabled={isResending}
                        style={{ opacity: isResending ? 0.7 : 1 }}
                    >
                        {isResending ? 'Sending...' : 'Resend Verification Email'}
                    </ResendLink>
                    {resendMessage && (
                        <StatusMessage error={isError}>
                            {resendMessage}
                        </StatusMessage>
                    )}
                </VerificationMessage>
            </Modal>
        </ModalBg>
    )
}

export default EmailVerificationModal