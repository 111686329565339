import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'
import { getCookie } from '../../helpers/getCookie'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import MobileMenu from './MobileMenu'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { 
    Nav, 
    NavDropdown,
    NavLink,
    PseudoLink,
    IconWrapper,
    NotificationWrapper,
    NotificationNumber,
    SearchButton,
    SearchInputWrapper,
    SearchInput,
    Separator,
} from './NavbarElements'

import NotificationModal from '../NonMainNav/NotificationModal'
import UserProfileModal from '../NonMainNav/UserProfileModal'
import DiscoverModal from '../NonMainNav/DiscoverModal'
import SearchResults from './SearchResults.js'
import EmailVerificationModal from '../EmailVerificationModal'

import { GoSearch } from 'react-icons/go'
import { PiUserCircleThin } from 'react-icons/pi'
import { IoIosNotificationsOutline, IoIosNotifications } from 'react-icons/io'
import { FaUserCircle } from 'react-icons/fa'
import { GoChevronDown } from 'react-icons/go'
import { FaBars } from 'react-icons/fa'

const Navbar = () => {
    const queryClient = useQueryClient()

    const { data: loggedInUser } = useQuery({
        queryKey: ['user'],
        queryFn: async () => {
            const token = getCookie('tokenPresence')
            if (!token) return { loggedIn: false }
            const response = await axios.get(`${API_URL}/login/me`, { withCredentials: true })
            const unreadCount = response.data.notifications.filter(n => !n.read).length
            return { ...response.data, loggedIn: true, unreadNotificationsCount: unreadCount }
        },
        staleTime: 1000 * 60 * 5, // 5 minutes, adjust as needed
    })

    const [showProfileModal, setShowProfileModal] = useState(false)
    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [showDiscoverDropdown, setShowDiscoverDropdown] = useState(false)
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const [showSearchInput, setShowSearchInput] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [page, setPage] = useState(1)
    const [showSearchResults, setShowSearchResults] = useState(false)
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)
    const [isSearchActive, setIsSearchActive] = useState(false)
    const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false)

    const discoverModalRef = useRef(null)
    const modalRef = useRef(null)
    const notificationModalRef = useRef(null)
    const iconWrapperRef = useRef(null)
    const discoverRef = useRef(null)
    const notificationIconRef = useRef(null)
    const searchInputRef = useRef(null)
    const searchResultsRef = useRef(null)
    const navigate = useNavigate()

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

    useEffect(() => {
        const handleResize = () => {
            if (!isMobile) {
                setShowMobileMenu(false)
                document.body.style.overflow = 'auto'
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [isMobile, setShowMobileMenu])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                searchResultsRef.current &&
                !searchResultsRef.current.contains(event.target) &&
                searchInputRef.current &&
                !searchInputRef.current.contains(event.target)
            ) {
                setShowSearchResults(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (loggedInUser?.notifications) {
            const unreadCount = loggedInUser.notifications.filter(n => !n.read).length
            setUnreadNotificationsCount(unreadCount)
        }
    }, [loggedInUser])

    const handleSearchClick = () => {
        setIsSearchActive(!isSearchActive);
        setShowSearchInput(!showSearchInput);
        if (!showSearchInput) {
            setTimeout(() => {
                searchInputRef.current.focus();
            }, 0)
        }
    }

    const handleNotificationClick = async () => {
        setShowNotificationModal(!showNotificationModal)
        if (!showNotificationModal) {
            try {
                await axios.post(`${API_URL}/notifications/mark-as-read`, {}, { withCredentials: true })
                // Update the unread count in your state
                setUnreadNotificationsCount(0)
                // Optionally, refetch the user data to get the updated notifications
                queryClient.invalidateQueries(['user'])
            } catch (error) {
                console.error('Failed to mark notifications as read:', error)
            }
        }
    }

    const handleClickOutside = (event) => {
        if (iconWrapperRef.current && iconWrapperRef.current.contains(event.target)) {
            setShowNotificationModal(false)
            setShowDiscoverDropdown(false)
            return
        }
        if (notificationIconRef.current && notificationIconRef.current.contains(event.target)) {
            setShowProfileModal(false)
            setShowDiscoverDropdown(false)
            return
        }
        if (discoverRef.current && discoverRef.current.contains(event.target)) {
            setShowProfileModal(false)
            setShowNotificationModal(false)
            return
        }
        if (discoverModalRef.current && !discoverModalRef.current.contains(event.target)) {
            setShowDiscoverDropdown(false)
        }
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowProfileModal(false)
        }
        if (notificationModalRef.current && !notificationModalRef.current.contains(event.target)) {
            setShowNotificationModal(false)
        }
        if (discoverModalRef.current && !discoverModalRef.current.contains(event.target)) {
            setShowDiscoverDropdown(false)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    const onChangeHandler = (e) => {
        setShowSearchResults(true)
        setSearchQuery(e.target.value)
        setPage(1)
    }

    const handleCreateProjectClick = () => {
        if (!loggedInUser?.loggedIn)
            navigate('/login')

        if (!loggedInUser.isEmailVerified) {
            setShowEmailVerificationModal(true)
            return
        }

        if (loggedInUser?.loggedIn && loggedInUser?.artist) {
            navigate('/create-project')
        } else if (loggedInUser?.loggedIn && !loggedInUser?.artist) {
            navigate('/artist-registry')
        }
    }

    const renderSearch = showSearchInput ? (
        <SearchInputWrapper>
            <GoSearch size={18} color='white' style={{ marginRight: 5 }} />
            <SearchInput 
                onClick={() => setShowSearchResults(true)} 
                onChange={onChangeHandler} 
                ref={searchInputRef} 
                value={searchQuery}
                placeholder="Search..." 
            />
        </SearchInputWrapper>
    ) : (
        <SearchButton onClick={handleSearchClick} >
            <GoSearch size={18} color='white' style={{ marginRight: 5 }} />
            Search
        </SearchButton>
    )

    const renderLogin = loggedInUser?.loggedIn
        ? 
        <div style={{ display: 'flex' }}>
            {showNotificationModal && <NotificationModal ref={notificationModalRef} setShowNotificationModal={setShowNotificationModal} notifications={loggedInUser.notifications} />}
            {showProfileModal && <UserProfileModal ref={modalRef} setShowProfileModal={setShowProfileModal} />}
            <NotificationWrapper>
                <IconWrapper ref={notificationIconRef} onClick={handleNotificationClick}>
                    {!showNotificationModal ? <IoIosNotificationsOutline size={29} color='white' /> : <IoIosNotifications size={29} color='white' />}
                    {unreadNotificationsCount > 0 && <NotificationNumber>{unreadNotificationsCount}</NotificationNumber>}
                </IconWrapper>
            </NotificationWrapper>
            <IconWrapper ref={iconWrapperRef} onClick={() => setShowProfileModal(!showProfileModal)}>
                {!showProfileModal ? <PiUserCircleThin color='white' size={30} style={{ cursor: 'pointer' }} /> : <FaUserCircle color='white' size={25} style={{ cursor: 'pointer' }} />}
            </IconWrapper>
        </div>
        : 
        <React.Fragment>
            <NavLink to='/login' >
                Login
            </NavLink>
            <NavLink to='/sign-up' style={{ padding: 0 }}>
                Sign Up
            </NavLink>
        </React.Fragment>

    const renderDesktopNavbar = (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <div style={{ position: 'relative', display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
                {showDiscoverDropdown && <DiscoverModal ref={discoverModalRef} setShowDiscoverDropdown={setShowDiscoverDropdown} />}
                <NavDropdown ref={discoverRef} onClick={() => setShowDiscoverDropdown(!showDiscoverDropdown)} >
                    Discover 
                    <GoChevronDown 
                        style={{ 
                            fontSize: 20, 
                            marginLeft: 5,
                            transform: showDiscoverDropdown ? 'rotate(-180deg)' : 'rotate(0deg)',
                        }}
                    />
                </NavDropdown>
                <PseudoLink onClick={handleCreateProjectClick} >
                    {loggedInUser?.loggedIn && loggedInUser?.artist ? 'Create a Project' : 'Artist Register'}
                </PseudoLink>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <NavLink to='/' >
                    <h1>muugen</h1>
                </NavLink>
            </div>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
                {renderSearch}
                <PseudoLink onClick={() => navigate('/posts')} >
                    Blog
                </PseudoLink>
                <Separator />
                {renderLogin}
            </div>
        </div>
    )

    const renderMobileNavbar = (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '10px'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
            }}>
                <NavLink to='/' style={{ fontSize: '20px', fontWeight: 'bold', flexShrink: 0 }}>
                    muugen
                </NavLink>
                <SearchInputWrapper style={{
                    flex: 1,
                    margin: '0 10px',
                    maxWidth: '50%'
                }}>
                    <GoSearch color='white' size={18} style={{ marginRight: 5 }} />
                    <SearchInput
                        onClick={() => setShowSearchResults(true)}
                        onChange={onChangeHandler}
                        ref={searchInputRef}
                        value={searchQuery}
                        placeholder="Search..."
                        style={{
                            borderBottom: '1px solid white',
                            fontSize: '14px',
                            width: 'calc(100% - 23px)' // Adjust for the search icon
                        }}
                    />
                </SearchInputWrapper>

                <div style={{ display: 'flex', alignItems: 'center', flexShrink: 0 }}>
                    {loggedInUser?.loggedIn ? (
                        <IconWrapper 
                            ref={iconWrapperRef} 
                            onClick={() => setShowProfileModal(!showProfileModal)}
                            style={{ width: '40px', height: '40px', marginRight: '5px' }}
                        >
                            {!showProfileModal ? 
                                <PiUserCircleThin color='white' size={28} style={{ cursor: 'pointer' }} /> : 
                                <FaUserCircle color='white' size={24} style={{ cursor: 'pointer' }} />
                            }
                        </IconWrapper>
                    ) : (
                        <NavLink to='/sign-up' 
                            style={{
                                minWidth: 60,
                                fontSize: '12px',
                                padding: '6px 8px',
                                marginRight: '5px'
                            }}
                        >
                            Sign Up
                        </NavLink>
                    )}
                    <IconWrapper 
                        onClick={() => setShowMobileMenu(!showMobileMenu)}
                        style={{ width: '40px', height: '40px' }}
                    >
                        <FaBars color={'white'} size={20} style={{ cursor: 'pointer' }} />
                    </IconWrapper>
                </div>
            </div>
    
            {showMobileMenu && (
                <MobileMenu
                    showMobileMenu={showMobileMenu}
                    setShowMobileMenu={setShowMobileMenu}
                    isMobile={isMobile}
                    loggedInUser={loggedInUser}
                />
            )}
            
            {showProfileModal && (
                <UserProfileModal 
                    ref={modalRef} 
                    setShowProfileModal={setShowProfileModal} 
                />
            )}
            
            {showDiscoverDropdown && (
                <DiscoverModal 
                    ref={discoverModalRef} 
                    setShowDiscoverDropdown={setShowDiscoverDropdown} 
                />
            )}
        </div>
    )

    return (
        <div >
            {showEmailVerificationModal && <EmailVerificationModal show={showEmailVerificationModal}  setShowEmailVerificationModal={setShowEmailVerificationModal}/>}
            <Nav style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ position: 'absolute', top: 50, left: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {searchQuery.length > 0 && showSearchResults && <SearchResults searchQuery={searchQuery} page={page} setPage={setPage} searchResultsRef={searchResultsRef} />}
                </div>
                {isMobile ? renderMobileNavbar : renderDesktopNavbar}
            </Nav>
        </div>
    )
}

export default Navbar