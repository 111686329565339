import React, { useState, useEffect, useCallback, useContext } from 'react'
import { AuthContext } from '../../authContext'
import axios from 'axios'
import { API_URL } from '../../config'
import {
    DiscoverArtistsContainer,
    ArtistsContainer,
    ArtistsWrapper
} from './DiscoverArtistElements'
import ArtistCard from './ArtistCard'
import FilterSection from './FilterSection'
import Pagination from '../Discover/Pagination'
import LoadingComponent from '../LoadingAsset'
import EmailVerificationModal from '../EmailVerificationModal'
import { NoProjects } from '../Discover/DiscoverElements'
import { toast } from 'react-toastify'

const DiscoverArtistsComponent = ({ initialArtists }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [searchedArtists, setSearchedArtists] = useState(initialArtists)
    const [searchTerm, setSearchTerm] = useState('')
    const [followMap, setFollowMap] = useState({}) // Map of artistId -> isFollowing
    const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false)
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
        totalPages: 0,
        totalArtists: 0
    })

    const { loggedInUser } = useContext(AuthContext)

    // Fetch follow relationships for all displayed artists
    const fetchFollowStatuses = useCallback(async (artistIds) => {
        if (!loggedInUser?.id) return

        try {
            const response = await axios.get(`${API_URL}/discover-artist/follows/status`, {
                params: { artistIds: artistIds.join(',') },
                withCredentials: true
            })
            
            setFollowMap(response.data.followStatuses)
        } catch (error) {
            console.error('Error fetching follow statuses:', error)
        }
    }, [loggedInUser])

    const fetchArtists = useCallback(async (searchTerm, page, limit) => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${API_URL}/discover-artist/artists`, {
                params: { search: searchTerm, page, limit }
            })
            const data = response.data
            setSearchedArtists(data.artists)
            setPagination(data.pagination)

            // Fetch follow statuses for the new set of artists
            if (data.artists.length > 0 && loggedInUser?.id) {
                await fetchFollowStatuses(data.artists.map(artist => artist._id))
            }
        } catch (error) {
            console.error('Error fetching artists:', error)
            toast.error('Error fetching artists. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }, [fetchFollowStatuses, loggedInUser])

    useEffect(() => {
        fetchArtists(searchTerm, pagination.page, pagination.limit)
    }, [fetchArtists, searchTerm, pagination.page, pagination.limit])

    const handleFollowToggle = async (artistId, currentStatus) => {
        if (!loggedInUser?.id) return

        if (!loggedInUser.isEmailVerified) {
            setShowEmailVerificationModal(true)
            return
        }

        try {
            if (currentStatus) {
                await axios.post(`${API_URL}/discover-artist/unfollow`, 
                    { followedArtistId: artistId }, 
                    { withCredentials: true }
                )
            } else {
                await axios.post(`${API_URL}/discover-artist/follow`, 
                    { followedArtistId: artistId }, 
                    { withCredentials: true }
                )
            }

            // Update local follow map
            setFollowMap(prev => ({
                ...prev,
                [artistId]: !currentStatus
            }))

            // Update artist follower count
            setSearchedArtists(prev => prev.map(artist => {
                if (artist._id === artistId) {
                    return {
                        ...artist,
                        followersCount: artist.followersCount + (currentStatus ? -1 : 1)
                    }
                }
                return artist
            }))

        } catch (error) {
            console.error('Error toggling follow:', error)
            toast.error('Failed to update follow status. Please try again.')
        }
    }

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value.toLowerCase()
        setSearchTerm(newSearchTerm)
        fetchArtists(newSearchTerm, 1, pagination.limit) // Reset to page 1 on new search
    }

    const handlePageChange = (newPage) => {
        fetchArtists(searchTerm, newPage, pagination.limit)
    }

    return (
        <DiscoverArtistsContainer>
            {showEmailVerificationModal && <EmailVerificationModal show={showEmailVerificationModal} setShowEmailVerificationModal={setShowEmailVerificationModal}/>}
            <h1>Discover Artists</h1>
            <FilterSection handleSearchChange={handleSearchChange} />
            {isLoading ? (
                <div style={{ height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <LoadingComponent />
                </div>
            ) : searchedArtists?.length > 0 ? (
                <>
                    <ArtistsWrapper>
                        <ArtistsContainer>
                            {searchedArtists.map((artist) => (
                                <div key={artist._id}>
                                    <ArtistCard
                                        loggedInUser={loggedInUser}
                                        artist={artist}
                                        isFollowing={followMap[artist._id] || false}
                                        onFollowToggle={(isFollowing) => handleFollowToggle(artist._id, isFollowing)}
                                    />
                                </div>
                            ))}
                        </ArtistsContainer>
                    </ArtistsWrapper>
                    <Pagination
                        currentPage={pagination.page}
                        totalPages={pagination.totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            ) : (
                <div style={{ width: '100%', marginTop: 50, paddingLeft: 30, paddingRight: 30 }}>
                    <NoProjects>
                        There are currently no artists that fit this description.
                    </NoProjects>
                </div>
            )}
        </DiscoverArtistsContainer>
    )
}

export default DiscoverArtistsComponent