import React, { useState, useEffect, useCallback, useContext } from 'react'
import axios from 'axios'
import { API_URL } from '../../config'
import { AuthContext } from '../../authContext'
import { DiscoverUsersWrapper } from './DiscoverUsersElements'
import { ArtistsContainer, ArtistsWrapper } from '../DiscoverArtists/DiscoverArtistElements'
import UserCard from './UserCard'
import FilterSection from '../DiscoverArtists/FilterSection'
import Pagination from '../Discover/Pagination'
import { toast } from 'react-toastify'
import LoadingComponent from '../LoadingAsset'
import EmailVerificationModal from '../EmailVerificationModal'
import { NoProjects } from '../Discover/DiscoverElements'

const DiscoverUsersComponent = ({ initialUsers }) => {
    const { loggedInUser } = useContext(AuthContext)
    const [users, setUsers] = useState(initialUsers)
    const [followMap, setFollowMap] = useState({}) // Map of userId -> isFollowing
    const [searchTerm, setSearchTerm] = useState('')
    const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false)
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 25,
        totalPages: 0,
        totalUsers: 0
    })
    const [isLoading, setIsLoading] = useState(false)

    // Fetch follow relationships for all displayed users
    const fetchFollowStatuses = useCallback(async (userIds) => {
        if (!loggedInUser?.id) return

        try {
            const response = await axios.get(`${API_URL}/discover-users/follows/status`, {
                params: { userIds: userIds.join(',') },
                withCredentials: true
            })
            
            setFollowMap(response.data.followStatuses)
        } catch (error) {
            console.error('Error fetching follow statuses:', error)
        }
    }, [loggedInUser])

    const fetchUsers = useCallback(async (search, page, limit) => {
        setIsLoading(true)
        try {
            const response = await axios.get(`${API_URL}/discover-users/users`, {
                params: { search, page, limit }
            })
            const { users, pagination } = response.data
            setUsers(users)
            setPagination(pagination)

            // Fetch follow statuses for the new set of users
            if (users.length > 0 && loggedInUser?.id) {
                await fetchFollowStatuses(users.map(user => user._id))
            }
        } catch (error) {
            console.error('Error fetching users:', error)
            toast.error('Failed to fetch users. Please try again.')
        } finally {
            setIsLoading(false)
        }
    }, [fetchFollowStatuses, loggedInUser])

    useEffect(() => {
        fetchUsers(searchTerm, pagination.page, pagination.limit)
    }, [fetchUsers, searchTerm, pagination.page, pagination.limit])

    const handleFollowToggle = async (userId, currentStatus) => {
        if (!loggedInUser?.id) return

        if (!loggedInUser?.isEmailVerified) {
            setShowEmailVerificationModal(true)
            return
        }

        try {
            if (currentStatus) {
                await axios.post(`${API_URL}/discover-users/unfollow`, { followedUserId: userId }, { withCredentials: true })
            } else {
                await axios.post(`${API_URL}/discover-users/follow`, { followedUserId: userId }, { withCredentials: true })
            }

            // Update local follow map
            setFollowMap(prev => ({
                ...prev,
                [userId]: !currentStatus
            }))

            // Update user follower count
            setUsers(prev => prev.map(user => {
                if (user._id === userId) {
                    return {
                        ...user,
                        followersCount: user.followersCount + (currentStatus ? -1 : 1)
                    }
                }
                return user
            }))

        } catch (error) {
            console.error('Error toggling follow:', error)
            toast.error('Failed to update follow status. Please try again.')
        }
    }

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value.toLowerCase()
        setSearchTerm(newSearchTerm)
        setPagination(prev => ({ ...prev, page: 1 }))
    }

    const handlePageChange = (newPage) => {
        setPagination(prev => ({ ...prev, page: newPage }))
    }

    return (
        <DiscoverUsersWrapper>
            {showEmailVerificationModal && <EmailVerificationModal show={showEmailVerificationModal} setShowEmailVerificationModal={setShowEmailVerificationModal} />}
            <h1>Discover Investors</h1>
            <FilterSection handleSearchChange={handleSearchChange} />
            {isLoading ? (
                <div style={{ height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <LoadingComponent />
                </div>
            ) : users?.length > 0 ? (
                <>
                    <ArtistsWrapper>
                        <ArtistsContainer>
                            {users?.map((user) => (
                                <UserCard 
                                    key={user?._id} 
                                    user={user}
                                    isFollowing={followMap[user._id] || false}
                                    onFollowToggle={(isFollowing) => handleFollowToggle(user._id, isFollowing)}
                                />
                            ))}
                        </ArtistsContainer>
                    </ArtistsWrapper>
                    <Pagination
                        currentPage={pagination.page}
                        totalPages={pagination.totalPages}
                        onPageChange={handlePageChange}
                    />
                </>
            ) : (
                <div style={{ width: '100%', marginTop: 50, paddingLeft: 30, paddingRight: 30 }}>
                    <NoProjects>
                        There are currently no investors that fit this description.
                    </NoProjects>
                </div>
            )}
        </DiscoverUsersWrapper>
    )
}

export default DiscoverUsersComponent