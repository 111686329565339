import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import NonMainNav from '../NonMainNav'

import {
    VerificationWrapper,
    VerificationCard,
    VerificationHeader,
    VerificationText,
    RedirectButton
} from './SignupElements.js'

function EmailVerified() {
    const navigate = useNavigate()

    useEffect(() => {
        // Auto-redirect after 5 seconds
        const timer = setTimeout(() => {
            navigate('/')
        }, 5000)

        return () => clearTimeout(timer)
    }, [navigate])

    return (
        <>
            <NonMainNav />
            <VerificationWrapper>
                <VerificationCard>
                    <VerificationHeader>Email Verified Successfully!</VerificationHeader>
                    <VerificationText>
                        Your email has been verified successfully. You can now access all features of your account.
                    </VerificationText>
                    <VerificationText>
                        Redirecting to homepage in 5 seconds...
                    </VerificationText>
                    <RedirectButton onClick={() => navigate('/')}>
                        Go to Homepage Now
                    </RedirectButton>
                </VerificationCard>
            </VerificationWrapper>
        </>
    )
}

export default EmailVerified