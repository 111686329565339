import React, { useEffect, useState } from 'react'
import { getCurrentInfoValue } from '../helpers/reducerHelper'

import { 
    InfoCardWrapper, 
    ProgressBarWrapper,
    ProgressBarAmount,
    ProgressBar, 
    Progress,
    InvestInfo,
    YourInvestment 
} from '../ProjectInvestElements'

import { addCommasToNumber } from '../../../helpers/numberConvert'

function InfoCard ({ project, state }) {
    const [funds, setFunds] = useState(0)
    const [targetFund, setTargetFund] = useState(0)

    useEffect(() => {
        if (project?.currentFunds)
            setFunds(project.currentFunds)

        if (project?.targetFundAmount)
            setTargetFund(project.targetFundAmount)
    }, [project?.currentFunds, project?.targetFundAmount])

    const calculateProgressWidth = () => {
        if (funds && targetFund) {
          const percentage = (funds / targetFund) * 100

          return `${percentage}%`
        }
        return '0%'
    }

    const formattedEndDateTime = new Date(project?.campaignEndDate).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short'
      })

    return (
        <InfoCardWrapper>
            <ProgressBarWrapper>
                <ProgressBarAmount>${addCommasToNumber(funds)}</ProgressBarAmount>
                <ProgressBar >
                    <Progress  style={{ width: calculateProgressWidth() }} />
                </ProgressBar>
                <ProgressBarAmount style={{ marginTop: 4, color: 'black' }}>of ${addCommasToNumber(targetFund)}</ProgressBarAmount>
                <InvestInfo>You are investing in {project ? project.projectName : ''} - <strong style={{ fontFamily: "'DM Sans', sans-serif" }}>{project?.projectCategory}</strong></InvestInfo>
                <YourInvestment>${getCurrentInfoValue('Investment Amount', state) === '' ? 0 : getCurrentInfoValue('Investment Amount', state)}</YourInvestment>
                <InvestInfo style={{ marginTop: 4, fontWeight: 700 }}>{getCurrentInfoValue('Investment Amount', state) === '' ? 0 : getCurrentInfoValue('Investment Amount', state)} shares</InvestInfo>
                <InvestInfo style={{ marginTop: 4 }}>
                    Campaign ends:
                </InvestInfo>
                <InvestInfo style={{ marginTop: 0, fontWeight: 700 }}>{formattedEndDateTime}</InvestInfo>
            </ProgressBarWrapper>
        </InfoCardWrapper>
    )
}

export default InfoCard